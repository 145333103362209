
import { Vue, Component, Watch } from 'vue-property-decorator'
import {
  Article, ArticlePageErrata, ArticlePageDefault,
  ArticlePageContainer, ArticlePageSharedReview,
} from '@/components/article-page'
import { ArticlesAPI } from '@/api'
import { UPDATE_PREV_NEXT_PUB_ID } from '@/store'

@Component({
  components: {
    ArticlePageContainer,
    ArticlePageDefault,
    ArticlePageErrata,
    ArticlePageSharedReview,
  },
})

export default class ArticlePage extends Vue {
  articleId: string | null = null
  article: Article | null = null
  smallLayout = false
  loading = false

  mounted() {
    document.title = 'Article - MathSciNet'
  }

  //
  // WATCHERS
  //

  @Watch('$route', { immediate: true })
  async onArticleIdChanged() {
    this.checkURLParams()
    await this.fetchData()
    this.$store.dispatch(UPDATE_PREV_NEXT_PUB_ID, { publicationId: this.article?.paperId })
  }

  @Watch('storeArticleId')
  onChangedStoreArticleId(newVal: string) {
    // newVal isn't always a string for some reason...
    newVal = String(newVal)
    if (this.articleId !== newVal) {
      // console.log('Article ID: ' + this.articleId + ' ' + typeof this.articleId)
      // console.log('new Value: ' + newVal + ' ' + typeof newVal)
      // console.log(this.articleId !== newVal)
      this.updateSearchParams({ articleId: this.storeArticleId })
    }
  }

  //
  // COMPUTED PROPERTIES
  //

  get storeArticleId() {
    return this.$store.state.PrevNextPublicationsModule.selectedPublicationId
  }

  relationType(article: Article | null): string {
    return article !== null && article.relation && article.relation.relationType
      ? article.relation.relationType.toLowerCase().replace(/\s/, '_')
      : ''
  }

  checkURLParams() {
    // this.articleId = this.$route.query.articleId ? +this.$route.query.articleId : -1
    this.articleId = this.$route.query.mr ? (this.$route.query.mr as string).replace('MR', '') : null
    if (this.articleId === null) {
      // support the old articleId
      this.articleId = this.$route.query.articleId ? this.$route.query.articleId as string : null
    }
  }

  updateSearchParams(options: { articleId: number }) {
    if (options.articleId === undefined) {
      return
    }
    this.$router.push({
      name: 'ArticlePage',
      query: {
        // articleId: options.articleId.toString(),
        mr: options.articleId.toString(),
      },
    })
  }

  async fetchData() {
    if (this.articleId === null) return

    this.loading = true
    this.article = await ArticlesAPI.getArticle(this.articleId)
    if (this.article !== null) {
      document.title = `MR${this.articleId} - MathSciNet`
    }
    this.loading = false
  }
}
